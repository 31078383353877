<template>
  <div class="building-page" v-if="building">
    <Header
      title="Roof Building"
      :type="this.headerTypes.PARTICULAR_ACCOMMODATION"
      :background="building.primaryImgPath"
      :building-name="building.buildingName"
      :description="building.description"
      show-button
      label="I wanna live here" @click="scrollToRooms"
    />
    <div class="arrows"/>
    <div class="building-infos" v-if="building.infos.length">
      <b-container class="building-info-container">
        <b-row>
          <b-col cols="12" md="4" class="info-container rf-orange-background py-5">
            <h1 class="info-title">{{ $t('Amenities All Included') }}</h1>
            <h3 class="info-subtitle">{{ $t('Meaning everything below is free of charge.') }}</h3>
            <b-row>
              <b-col cols="12" sm="6" md="12" class="building-info" v-for="(info, index) in building.infos" :key="index"
                     @click="onSelectImage(info, index + 1)">
                <img class="info-logo"
                     :src="selectedLogo === index + 1 ? '/images/arrow_white_hover.png' : '/images/arrow_white.png'">
                <div class="info-name" :class="{ 'active': selectedLogo === index + 1 }">
                  {{ info.title.translations[$i18n.locale] || info.title.defaultValue }}
                  <p class="info-description">
                    {{ info.description.translations[$i18n.locale] || info.description.defaultValue }}</p>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
      <div class="image-selected" :style="{ backgroundImage: `url(${getImage(selectedImage)})` }"></div>
    </div>
    <div class="building-characteristics" v-if="building.characteristics && building.characteristics.length">
      <b-container>
        <b-row>
          <b-col>
            <h1 class="characteristics-title">{{ $t('Everything you need for a comfortable stay') }}</h1>
          </b-col>
        </b-row>
        <b-row class="justify-content-center">
          <b-col cols="12" md="4" lg="2" v-for="(characteristic, index) in building.characteristics" :key="index"
                 class="characteristic-container my-4">
            <img class="characteristic-icon" v-if="characteristic.iconPath" :src="characteristic.iconPath | getAsset"/>
            <div class="characteristic" v-else/>
            <div class="characteristic-type">
              {{ $t(characteristic.translations[$i18n.locale] || characteristic.defaultValue) }}
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-container fluid class="building-map">
      <b-row>
        <b-col cols="12" md="6" class="px-0 pl-lg-0">
          <GmapMap :center="buildingPosition || { lat: 45.4640976, lng: 9.1897378 }" :zoom="13" class="gmap" ref="gmap">
            <GmapMarker v-if="buildingPosition" :icon="buildingMarker" :key="building.id" :position="buildingPosition"
                        :clickable="true" @click="openMarkerInfo = true">
              <GmapInfoWindow v-if="openMarkerInfo"
                              :position="buildingPosition"
                              @closeclick="openMarkerInfo = false"
                              :options="{ pixelOffset: { width: 0, height: 0 } }">
                <div class="mr-4">
                  {{ `${building.buildingName} - ${building.addressStreet}, ${building.addressZipCode}` }}
                </div>
              </GmapInfoWindow>
            </GmapMarker>
            <template v-if="currentPlacesOfInterest.length">
              <GmapMarker @click="selectPlaceInMap(placeOfInterest,index)" :icon="placeOfInterest.icon"
                          :position="{ lat: placeOfInterest.lat, lng: placeOfInterest.lng }"
                          v-for="(placeOfInterest, index) in placesOfInterest[this.typeSelected].places"
                          :key="`place-${index}`">
                <GmapInfoWindow
                  v-if="selectedPlace===index"
                  :position="{ lat: placeOfInterest.lat, lng: placeOfInterest.lng }"
                  :options="{ pixelOffset: { width: 0, height: 0 } }">
                  <div class="mr-4" v-if="placeOfInterest.address!== undefined">
                    {{ `${placeOfInterest.name} - ${placeOfInterest.address}` }}
                  </div>
                  <div class="mr-4" v-else>
                    {{ `${placeOfInterest.name}` }}
                  </div>
                </GmapInfoWindow>
              </GmapMarker>
            </template>
          </GmapMap>
        </b-col>
        <b-col cols="12" md="6" class="mt-4 mt-md-0 pt-md-3 pb-5">
          <h1 class="gmap-option-title">{{ $t('Many Things') }}</h1>
          <h1 class="gmap-option-title orange">{{ $t('To Do And Discover') }}</h1>
          <img class="gmap-waves" src="/images/orange_roofs.png">
          <div class="filter-cancel cursor-pointer ml-2" v-if="currentPlacesOfInterest.length"
               @click="resetPlacesFilter">
            <FontAwesomeIcon :icon="['fas', 'times']" color="#E26F5A"/>
            {{ $t(`Cancel filter`) }}
          </div>
          <RFRadio name="gmap" @input="selectPlaceType"
                   v-model="gmapSelectedOption"
                   label-color="#E26F5A"
                   color="#E26F5A"
                   :inline="false"
                   :list="placesList"/>
        </b-col>
      </b-row>
    </b-container>
    <!--    <b-container>-->
    <!--      <b-row class="justify-content-center mb-4 mt-5">-->
    <!--        <b-col cols="6" md="auto">-->
    <!--          <div class="toggle-filters mr-3" @click="showFilters = !showFilters">-->
    <!--            <transition name="fade">-->
    <!--              <span class="show" v-if="!showFilters">-->
    <!--                <RFButton class="my-3" :label="$t('Add Filters')" full-width background="#E26F5A" color="#fff" border-color="transparent"/>-->
    <!--              </span>-->
    <!--              <span class="hide" v-else>-->
    <!--                 <RFButton class="my-3" :label="$t('Hide Filters')" full-width background="#E26F5A" color="#fff" border-color="transparent"/>-->
    <!--              </span>-->
    <!--            </transition>-->
    <!--          </div>-->
    <!--        </b-col>-->
    <!--      </b-row>-->
    <!--    </b-container>-->
    <b-container v-if="showFilters">
      <b-row class="justify-content-center mb-5">
        <b-col cols="12" md="6" class="mt-3 mt-md-0 px-0 pr-md-3">
          <div class="booking-form-container">
            <ValidationObserver ref="validator">
              <form class="booking-form" @submit.prevent="search">
                <b-row class="d-flex justify-content-between align-items-center">
                  <b-col cols="12" lg="4">
                    <div class="booking-label">{{ $t('Arrival') }}</div>
                  </b-col>
                  <b-col cols="12" lg="6">
                    <RFValidation rules="required">
                      <RFMonthPicker v-model="searchParams.startDate" :min="currentMonth"
                                     :special-format="specialDateFormat"/>
                    </RFValidation>
                  </b-col>
                </b-row>
                <b-row class="d-flex justify-content-between align-items-center">
                  <b-col cols="12" lg="4">
                    <div class="booking-label">{{ $t('Departure') }}</div>
                  </b-col>
                  <b-col cols="12" lg="6">
                    <RFValidation rules="required">
                      <RFMonthPicker v-model="searchParams.endDate" :min="currentMonth"
                                     :special-format="specialDateFormat" :ending-date="true"/>
                    </RFValidation>
                  </b-col>
                </b-row>
                <b-row class="d-flex justify-content-center">
                  <b-col cols="6">
                    <RFButton class="my-3" :label="$t('Filter')" full-width background="#74776D" color="#fff"
                              border-color="transparent"/>
                  </b-col>
                </b-row>
              </form>
            </ValidationObserver>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div class="admin-loading" v-if="loading">
      <RFLoader class="mr-3" color="#24425B" :size="30"/>
    </div>
    <div class="building-rooms py-5" ref="rooms">
      <RoomList :list="building.roomsFiltered" v-if="!loading && building.roomsFiltered.length"/>
      <b-container v-if="!loading && !building.roomsFiltered.length">
        <b-row class="d-flex justify-content-center">
          <b-col cols="12">
            <div class="empty-list">{{ $t('No rooms were found') }}</div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import RoomList from '@/components/accomodations/rooms/RoomList'
import RFRadio from '@/components/forms/RFRadio'
import RFMonthPicker from "@/components/forms/RFMonthPicker";
import RFValidation from "@/components/forms/RFValidation";
import RFLoader from "@/components/forms/RFLoader";
import RFButton from "@/components/forms/RFButton";
import {getAsset} from '@/helpers'
import {store} from '@/stores'
import {apartmentService, buildingService} from '@/services'
import {headerTypes} from '@/enums'
import moment from "moment";

export default {
  components: {
    Header,
    RoomList,
    RFRadio,
    RFMonthPicker,
    RFLoader,
    RFValidation,
    RFButton
  },
  /*  beforeRouteEnter(to, from, next) {
      if (!store.state.buildingStore.building) {
        next({ name: 'buildings-page' })
      } else {
        next()
      }
    },*/
  data() {
    return {
      headerTypes,
      building: null,
      selectedImage: null,
      selectedLogo: null,
      openMarkerInfo: false,
      gmapSelectedOption: null,
      placesOfInterest: {},
      currentPlacesOfInterest: [],
      selectedPlace: null,
      placesList: [
        {
          label: 'Move',
          value: 'Move',
          values: ['airport', 'bus_station', 'light_rail_station', 'subway_station', 'taxi_stand', 'train_station', 'transit_station'],
          icon: '/images/move.png'
        },
        {
          label: 'Play',
          value: 'Play',
          values: ['art_gallery', 'movie_theater', 'museum', 'night_club', 'park', 'shopping_mall', 'store', 'tourist_attraction'],
          icon: '/images/play.png'
        },
        {
          label: 'Eat',
          value: 'Eat',
          values: ['bar', 'cafe', 'meal_delivery', 'meal_takeaway', 'restaurant', 'supermarket'],
          icon: '/images/eat.png'
        },
      ],
      buildingMarker: null,
      SECONDS_BETWEEN_NEXT_PAGE_CALLS: 2,
      MAX_PAGE_LIMIT: 3,
      MAX_RADIUS: 5000,
      MAP_ZOOM: 15,
      mapCenter: {
        lat: null,
        lng: null
      },
      typeSelected: null,
      specialDateFormat: true,
      searchParams: {
        startDate: null,
        endDate: null,
      },
      loading: false,
      showFilters: false
    }
  },
  computed: {
    buildingPosition() {
      return this.building && this.building.addressLatitude && this.building.addressLongitude ? {
        lat: parseFloat(this.building.addressLatitude),
        lng: parseFloat(this.building.addressLongitude),
      } : null
    },
    currentMonth() {
      return moment().format('YYYY-MM')
    }
  },
  async beforeMount() {
    if (store.state.buildingStore.building && store.state.buildingStore.building.buildingName.toUpperCase() === this.$route.params.name.toUpperCase()) {
      this.building = store.state.buildingStore.building
    } else {
      this.building = await buildingService.getBuildingByNameFilteredRooms(this.$route.params.name, this.searchParams.startDate, this.searchParams.endDate)
      this.$store.commit('buildingStore/setBuilding', this.building)
    }
    this.setUpPlacesOfInterest()

    this.selectedImage = this.building && this.building.infos.length ? this.building.infos[0].imagePath : null
    this.selectedLogo = 1

    this.$nextTick(() => {
      this.$refs.gmap.$mapPromise.then(map => {
        this.buildingMarker = {
          url: '/images/arrow_orange_hover.png',
          scaledSize: new google.maps.Size(30, 20),
        }
      })
    })
  },
  beforeDestroy() {
    this.$store.commit('roomStore/setRoom', null)
  },
  methods: {
    scrollToRooms() {
      const offset = this.$refs.rooms.offsetTop

      window.scroll({
        top: offset - 86,
        behavior: 'smooth',
      })
    },
    onSelectImage(info, index) {
      this.selectedImage = info.imagePath
      this.selectedLogo = index
    },
    getImage(image) {
      return getAsset(image)
    },
    resetPlacesFilter() {
      this.currentPlacesOfInterest = []
      this.selectedPlace = null
      this.typeSelected = null
      this.gmapSelectedOption = null
    },
    createGoogleMapsService(lat, long) {
      const location = new google.maps.LatLng(lat, long)

      const map = new google.maps.Map(this.$refs.gmap, {
        center: location,
        zoom: this.MAP_ZOOM,
      })

      return new google.maps.places.PlacesService(map)

    },
    setUpPlacesOfInterest() {
      this.placesList.forEach(placeList => {
        this.placesOfInterest[placeList.label] = {
          places: [],
          pageCount: new Array(placeList.values.length).fill(0),
          icon: placeList.icon
        }
      })
    },
    async selectPlaceType(label) {
      this.selectedPlace = null
      this.currentPlacesOfInterest = this.placesOfInterest[label].places
      if (!this.placesOfInterest[label].places.length) {
        await this.getPlacesOfInterestOfAType(label)
      }
      this.typeSelected = label
    },
    async selectPlaceInMap(place, index) {
      const service = this.createGoogleMapsService(this.mapCenter.lat, this.mapCenter.lng)
      const request = {
        placeId: place.id,
        fields: ['formatted_address']
      }
      await service.getDetails(request, (placeResponse, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          place.address = placeResponse.formatted_address
          this.selectedPlace = index
        }
      })
    },
    async getPlacesOfInterestOfAType(label) {
      this.mapCenter.lat = this.buildingPosition.lat
      this.mapCenter.lng = this.buildingPosition.lng
      const location = new google.maps.LatLng(this.buildingPosition.lat, this.buildingPosition.lng)
      const service = this.createGoogleMapsService(this.buildingPosition.lat, this.buildingPosition.lng)
      let placeType = this.placesList.find(place => place.label === label)
      let placeTypeValues = placeType.values
      return await Promise.all(
        placeTypeValues.map(async (type, index) => {
          const request = {
            location,
            radius: this.MAX_RADIUS,
            type: type,
          }
          await service.nearbySearch(request, async (results, stat, pagination) => {
            if (stat === google.maps.places.PlacesServiceStatus.OK) {
              results.forEach(result => {
                this.placesOfInterest[label].places.push({
                  name: result.name,
                  lat: result.geometry.location.lat(),
                  lng: result.geometry.location.lng(),
                  id: result.place_id,
                  types: result.types,
                  icon: {
                    url: this.placesOfInterest[label].icon,
                    scaledSize: new google.maps.Size(30, 30),
                  },
                })
              })
              if (pagination != null && pagination.hasNextPage && this.placesOfInterest[label].pageCount[index] < this.MAX_PAGE_LIMIT) {
                this.placesOfInterest[label].pageCount[index]++
                setTimeout(pagination.nextPage(), 1000 * this.SECONDS_BETWEEN_NEXT_PAGE_CALLS)
              }
            }
          })
        })
      )
    },
    async search() {
      const isValid = await this.$refs.validator.validate()

      if (isValid) {
        this.loading = true
        this.building = await buildingService.getBuildingByNameFilteredRooms(this.$route.params.name, this.searchParams.startDate, this.searchParams.endDate)
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.building-page {
  .arrows {
    background-image: url("~@/assets/images/orange_texture.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 200px;
    margin-top: 50px;
  }

  .building-infos {
    // background-color: $orange;
    position: relative;
    min-height: 400px;

    .image-selected {
      position: relative;
      min-height: 400px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      z-index: 5;
    }

    .building-info-container {
      position: relative;
      z-index: 5;

      .info-container {
        min-height: 400px;
        position: relative;
        z-index: 5;

        &:before,
        &:after {
          content: '';
          position: absolute;
          width: 100vw;
          height: 100%;
          background-color: $orange;
          top: 0;
          z-index: -1;
        }

        &:before {
          left: -100vw;
        }

        &:after {
          left: 100%;
        }
      }
    }

    .info-title {
      @include font-style($montserrat, 'semibold', $font-30);
      text-transform: uppercase;
      color: $white;
    }

    .info-subtitle {
      @include font-style($montserrat, 'semibold', $font-16);
      color: $beige;
      margin-bottom: 30px;
    }

    .building-info {
      display: flex;
      align-items: center;
      margin: 0 0 20px 0;
      cursor: pointer;

      .info-logo {
        width: 50px;
        margin-right: 10px;
        // display: none;
      }

      .info-name {
        @include font-style($montserrat-bold, 'bold', $font-18);
        color: $white;
        transition: color .3s;

        &.active {
          color: $beige;
        }

        .info-description {
          @include font-style($montserrat, 'medium', $font-12);
          margin: 0;
        }
      }
    }

    .selected-image {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      min-height: 400px;
      transition: background-image .3s;
    }
  }

  .building-characteristics {
    background-color: $beige;
    padding: 30px 0;

    .characteristics-title {
      @include font-style($montserrat, 'semibold', $font-20);
      text-align: center;
      text-transform: uppercase;
      color: $dark-blue;
      margin: 20px 0 40px 0;
    }

    .characteristic-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;

      .characteristic {
        width: 45px;
        height: 45px;
        border: 1px solid $white;
      }

      .characteristic-icon {
        width: 45px;
        height: 45px;
      }

      .characteristic-type {
        @include font-style($montserrat, 'semibold', $font-14);
        color: $dark-blue;
        text-transform: capitalize;
        margin-top: 10px;
      }
    }
  }

  .building-map {
    .gmap {
      height: 40vh;
    }

    .gmap-option-title {
      @include font-style($montserrat, 'medium', $font-24);
      text-transform: uppercase;

      &.orange {
        margin-bottom: 30px;
        color: $orange;
      }
    }

    .gmap-waves {
      width: 130px;
      position: relative;
      margin: 30px 0;
      display: none;
    }
  }

  .building-rooms {
    background-color: $gray;
  }

  .booking-form-container {
    background-color: $dark-blue;
    padding: 15px;
    position: relative;
    z-index: 5;

    &:before {
      content: '';
      height: 100%;
      background-color: $dark-blue;
      position: absolute;
      top: 0;
      z-index: -1;
    }

    &:before {
      right: 50%;
    }

    .booking-label {
      @include font-style($montserrat-bold, 'bold', $font-14);
      color: $white;
      margin-top: 20px;
      white-space: nowrap;
    }
  }

  .toggle-filters {
    @include font-style($montserrat-bold, 'bold', $font-14);
    color: $dark-blue;
    text-transform: uppercase;
    cursor: pointer;
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  .building-page {
    .building-infos {
      .building-info {
        .info-name {
          margin-left: 30px;
        }

        .info-logo {
          display: block;
          margin-right: 0;
        }
      }

      .image-selected {
        position: absolute;
        top: 0;
        left: 35%;
        width: 65%;
        height: 100%;
      }
    }

    .building-map {
      .gmap {
        height: 50vh;
      }

      .gmap-option-title {
        &.orange {
          margin: 0;
        }
      }

      .gmap-waves {
        display: block;
      }
    }
  }
}
</style>
