<template>
  <div class="room-list">
    <RoomItem v-for="(item, index) in list" :item="item" :key="index" />
  </div>
</template>

<script>
import RoomItem from '@/components/accomodations/rooms/RoomItem'

export default {
  props: {
    list: {
      type: Array,
    }
  },
  components: { RoomItem },
}
</script>

<style lang="scss" scoped>
</style>
