<template>
  <div class="room">
    <b-container class="room-container">
      <b-row>
        <b-col class="pr-lg-0 mt-0 order-md-1" cols="12" lg="4" order="1" order-lg="0">
          <div class="room-box mt-0 mb-5 my-lg-5">
            <div class="room-box-header">
              <div class="room-title">
                <h1 v-if="item.type.title" class="room-type">{{ item.type.title }}</h1>
                <h3 v-if="item.type.subtitle" class="room-subtype">{{ item.type.subtitle }}</h3>
              </div>
            </div>
            <div class="room-box-content">
              <div class="room-detail">
                <FontAwesomeIcon :icon="['fas', 'angle-right']"/>
                {{ $t('Guest') }}
                <div v-for="(guest, index) in item.numOfPerson" :key="index" class="content mx-1">
                  <FontAwesomeIcon :icon="['fas', 'user']"/>
                </div>
              </div>
              <div class="room-detail">
                <FontAwesomeIcon :icon="['fas', 'angle-right']"/>
                {{ $t('Size') }} <span class="content">{{ item.sizeRange }} m<sup>2</sup></span>
              </div>
              <div class="room-detail">
                <FontAwesomeIcon :icon="['fas', 'angle-right']"/>
                {{ $t('Bathroom') }} <span class="content">{{ item.bathroomType | capitalize }}</span>
              </div>
              <div class="room-detail">
                <FontAwesomeIcon :icon="['fas', 'angle-right']"/>
                {{ $t('Beds') }} <span class="content">{{ beds.join(' or ') }}</span>
              </div>
              <div class="room-price">
                <p class="starting">{{ $t('Starting from') }}</p>
                <span class="price">&euro; {{ item.type.priceToShow }}</span>
                <!--span class="price">&euro; {{item.type.priceTag}}</!--span-->
                <span class="month">/{{ $t('month') }}
                  <template v-if="item.type.isShared">
                    {{ $t('per person') }}
                  </template>
                </span>
              </div>
            </div>
            <div class="room-box-footer mt-4">
              <RFButton background="#fff" border-size="3" color="#E26F5A" font-weight="bold" full-width
                        label="Booking Request"
                        @on-click="roomReservation(item)"/>
            </div>
          </div>
        </b-col>
        <b-col class="room-images pl-lg-0 mt-5 mt-lg-0" cols="12" lg="8" order="0" order-lg="1">
          <VueperSlides :fixed-height="isLg ? '100%' : '300px'" :slide-ratio="0.7" :touchable="false">
            <VueperSlide v-for="(image, index) in item.images" :key="index">
              <template #content v-bind:class="[ image.pathname.split('.')[1]==='mp4' ? 'video-container' : '']">
                <div v-if="image.pathname.split('.')[1]!=='mp4'" class="vueperslide__content-wrapper">
                  <b-img :src="image.smallBuildingImageFilepath || null | getAsset" class="sliderCursor" fluid
                         @click="showModal(index)"/>
                </div>
                <video v-if="image.pathname.split('.')[1]==='mp4'" autoplay class="sliderCursor" loop muted playsinline
                       @click="showModal(index)">
                  <source :src="image.pathname | getAsset" type="video/mp4">
                </video>
              </template>
            </VueperSlide>
          </VueperSlides>
        </b-col>
      </b-row>
    </b-container>
    <b-modal v-model="showCarouselModal" hide-footer>
      <VueperSlides :touchable="false" arrows-outside fixed-height="85vh" fractions>
        <VueperSlide v-for="(image, index) in imageArray" :key="index">
          <template #content v-bind:class="[ image.pathname.split('.')[1]==='mp4' ? 'video-container' : '']">
            <b-img v-if="image.pathname.split('.')[1]!=='mp4'"
                   :src="image.largeImageFilepath || null | getAsset"
                   class="image_fluid sliderCursor centered"/>
            <video v-if="image.pathname.split('.')[1]==='mp4'" autoplay class="sliderCursor centered" loop muted
                   playsinline>
              <source :src="image.pathname | getAsset" type="video/mp4">
            </video>
          </template>
        </VueperSlide>
      </VueperSlides>
    </b-modal>
  </div>
</template>

<script>
import RFButton from '@/components/forms/RFButton'
import {accommodations} from '@/enums'
import {mapGetters} from 'vuex'
import {capitalize} from '@/helpers';

export default {
  data: function () {
    return {
      showCarouselModal: false,
      indexSlide: 0,
      imageArray: [],
    }
  },
  components: {
    RFButton,
  },
  props: {
    item: {
      type: Object,
    }
  },
  computed: {
    ...mapGetters('windowStore', ['isLg']),
    beds() {
      return Object.entries(this.item.beds).map(entry => (`${entry[1]} ${entry[0]}`))
    }
  },
  created() {
    if (this.item && this.item.images) {
      this.item.images.sort((a, b) => a.orderIndex - b.orderIndex);
    }
  },
  methods: {
    capitalize,
    showModal(index) {
      this.indexSlide = index
      let obj = this.item.images[this.indexSlide]
      this.imageArray = this.item.images;
      this.imageArray.splice(this.indexSlide, 1);
      this.imageArray.unshift(obj)
      this.showCarouselModal = true
    },
    roomReservation(room) {
      const reservation = {
        item: room,
        type: accommodations.ROOM,
        buildingId: this.$store.state.buildingStore.building.id,
      }

      this.$store.commit('roomStore/setRoom', room)
      this.$store.commit('reservationStore/setReservation', reservation)
      this.$router.push('/booking-request')
    },
  }
}
</script>

<style lang="scss" scoped>
.room {
  background-color: $orange;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 50px;

  &:after {
    content: '';
    position: absolute;
    background-color: $gray;
    height: 100%;
    width: 50%;
    top: 0;
    left: 50%;
    display: none;
  }

  .room-container {
    position: relative;
    z-index: 5;

    .room-box {
      background-color: $white;
      display: flex;
      padding: 20px 15px;
      flex-direction: column;
      justify-content: space-between;

      .room-box-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $orange;

        .room-type {
          @include font-style($montserrat-bold, 'bold', $font-20);
        }

        .room-title {
          @include font-style($montserrat-light, 'light', $font-22);
          text-transform: uppercase;
        }
      }

      .room-box-content {
        .room-detail {
          @include font-style($montserrat-bold, 'bold', $font-14);
          color: $orange;
          margin: 10px 0;

          .content {
            @include font-style($montserrat, 'medium', $font-14);
            display: inline-block;
            color: $dark-blue;
          }
        }

        .room-price {
          @include font-style($montserrat, 'regular', $font-14);
          margin-top: 30px;
          color: $dark-blue;
          line-height: 20px;

          .starting,
          .month {
            text-transform: lowercase;
            margin: 0;
          }

          .price {
            @include font-style($montserrat-bold, 'bold', $font-26);
          }
        }
      }
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  .room {
    &:after {
      display: block;
    }

    .room-container {
      .room-box {
        padding: 30px 40px;
      }
    }
  }
}
</style>

<style css>
.modal-header {
  background-color: transparent !important;
  padding: 0px !important;
  margin: 0px !important;
  border: 0px !important;
}

.image_fluid {
  object-fit: scale-down;
  max-width: 100%;
  max-height: 100%;
}

.vueperslides__arrow {
  color: white;
}

.modal-dialog {
  max-width: 95vw;
  max-height: 95vh;
  padding: 5px;
}

.modal-content {
  text-align: center !important;
  border-radius: 0 !important;
  border: 0px solid white !important;
  background-color: transparent !important;
}

.modal-header {
  background-color: #24425b;
  color: white;
  border-radius: 0;
}

.modal .close {
  outline: none;
  color: white;
}

.vueperslide--loading .vueperslide__content-wrapper {
  display: none !important;
}

</style>
